<template>
  <div id="DonationStage">
    <div class="Container" style="padding-top: 0px; padding-bottom: 0px;" align="center">
        <donate-palette />
    </div>
  </div>
</template>

<script>
import DonateWidget from '../components/DonateWidget.vue'

export default {
    data () {
        return {
        }
    },

    components: {
        'donate-palette': DonateWidget
    },

    computed: {
    },

    methods: {
    },

    beforeMount: function () {
    },

    mounted: function () {
    }
}
</script>

<style scoped>
.ClearBoth {
  clear: both;
  height: 0px;
}

h1 {
  text-transform: uppercase;
  margin-bottom: 40px;
  font-weight: bolder;
}

#CaseSelectorStage {
  padding-left: 20px;
  padding-right: 20px;
  background-color: #F0F0F0;
}

#bbox-root {
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
}
</style>
