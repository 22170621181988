<template>
  <div id="CardStage">
    <transition name='fade'>
      <div v-if="displayForm">
        <div class="CaseCard FormFace">
          <div class="GratitudeStmt">Your assistance will make all the difference in the world to this person.</div>
            <div v-if="isStudentCase">
              <el-form :model="studentForm" :rules="rules" ref="studentForm" label-position="top">
                <el-form-item label="Your Name" prop="name">
                  <el-input v-model="studentForm.name"></el-input>
                </el-form-item>
                <el-form-item label="Your Phone No." prop="phone">
                  <el-input v-model="studentForm.phone" placeholder="407-867-5309" ></el-input>
                </el-form-item>
                <el-form-item style="text-align: left; line-height: 12px;" label="Your School Email (.edu only)" prop="email">
                  <el-input v-model="studentForm.email"></el-input>
                </el-form-item>
                <el-form-item label="Availability, location, and comments" prop="comment">
                  <el-input v-model="studentForm.comment"></el-input>
                </el-form-item>
                <div :id="recaptchaWidgetId" class="g-recaptcha" style="transform:scale(0.78);-webkit-transform:scale(0.78);transform-origin:0 0;-webkit-transform-origin:0 0; margin-top: 30px;"></div>
                <div id="FormControls">
                  <div v-if="formSubmissionStatus === 0">
                    <el-button class="HelpButton" type="primary" @click="onSubmit">Submit</el-button>
                    <el-button class="CancelButton" @click="toggleForm()">Cancel</el-button>
                  </div>
                  <div v-else-if="formSubmissionStatus === 1" align="center">
                    <el-button type="info" @click="onSubmit">Sending Form</el-button>
                  </div>
                  <div v-else-if="formSubmissionStatus === 2" align="center">
                    <p>{{errorMessage}}</p>
                    <el-button type="danger" @click="onSubmit">Try Again</el-button>
                  </div>
                  <div v-else align="center">
                    <p>Your interest form was saved.</p>
                    <el-button type="success" @click="toggleForm">Next</el-button>
                  </div>
                </div>
              </el-form>
            </div>
            <div v-else>
              <el-form :model="alumniForm" :rules="rules" ref="alumniForm" label-position="top">
                <el-form-item label="Your Name" prop="name">
                  <el-input v-model="alumniForm.name"></el-input>
                </el-form-item>
                <el-form-item label="Your Phone No." prop="phone">
                  <el-input v-model="alumniForm.phone"></el-input>
                </el-form-item>
                <el-form-item label="Your Email" prop="email">
                  <el-input v-model="alumniForm.email"></el-input>
                </el-form-item>
                <el-form-item label="Your Florida Bar No." prop="barno">
                  <el-input v-model="alumniForm.barno"></el-input>
                </el-form-item>
                <el-form-item label="Comment" prop="comment">
                  <el-input v-model="alumniForm.comment"></el-input>
                </el-form-item>
                <el-switch
                    v-model="alumniForm.mentorNeeded"
                    prop="mentorNeeded"
                    active-text="Newer Attorney"
                    inactive-text="Experienced">
                </el-switch>
              </el-form>
              <div :id="recaptchaWidgetId" class="g-recaptcha" style="transform:scale(0.78);-webkit-transform:scale(0.78);transform-origin:0 0;-webkit-transform-origin:0 0; margin-top: 30px;"></div>
              <div id="FormControls">
                <div v-if="formSubmissionStatus === 0">
                    <el-button class="HelpButton" type="primary" @click="onSubmit">Submit</el-button>
                    <el-button class="CancelButton" @click="toggleForm()">Cancel</el-button>
                  </div>
                  <div v-else-if="formSubmissionStatus === 1" align="center">
                    <el-button type="info" @click="onSubmit">Sending Form</el-button>
                  </div>
                  <div v-else-if="formSubmissionStatus === 2" align="center">
                    <p>{{errorMessage}}</p>
                    <el-button type="danger" @click="onSubmit">Try Again</el-button>
                  </div>
                  <div v-else align="center">
                    <p>Your interest form was saved.</p>
                    <el-button type="success" @click="toggleForm">Next</el-button>
                  </div>
              </div>
            </div>
        </div>
      </div>
      <div v-else>
        <div class="CaseCard InformationFace">
          <img :src="iconPath" class="CaseTypeIcon" width="40" height="40" :alt="iconDescription" @cick="saveCase(0)">
          <h2>{{ this.formattedPartnerName }}</h2>
          <h2>Ref No.: {{ identificationNumber }}</h2>
          <div class="ClearBoth">&nbsp;</div>
          <h1>{{ opportunityNote }}</h1>
          <p id="OppoSummaryNarrative">{{ opportunitySummary }}</p>
          <div id="CaseChips">
            <div v-if="isTaggedChallengeReady">
              <el-button class="chip" type="success" round>
                <div>
                  <div class="material-icons">favorite</div>
                  <div class="IconButtonLabel">Challenge</div>
                </div>
              </el-button>
            </div>
            <div v-if="isTaggedRemote">
              <el-button class="chip" type="info" round>
                <div>
                  <div class="material-icons">videocam</div>
                  <div class="IconButtonLabel">Remote</div>
                </div>
              </el-button>
            </div>
            <div v-if="isPreferredPartner">
              <el-button class="chip" type="primary" round>
                <div>
                  <div class="material-icons">star</div>
                  <div class="IconButtonLabel">Preferred</div>
                </div>
              </el-button>
            </div>
            <div class="ClearBoth">&nbsp;</div>
          </div>
          <div v-if="!isStudentCase">
            <div>{{ "Student Initials: " + studentInitials }}</div>
            <div class="StudentCommentRpt">{{ "Student Comment: " + studentComment }}</div>
          </div>
          <div class="ClearBoth">&nbsp;</div>
          <div id="CaseCardControls">
            <img :src="mapPath" width="240" height="120" class="MapImage" :alt="mapDescription">
            <el-button class="HelpButton" type="primary" @click="toggleForm()">Interested?</el-button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from 'axios'
import { ElButton, ElForm, ElFormItem, ElInput, ElSwitch } from 'element-plus'
import EventBus from '../EventBus'
import { store } from '../store'

export default {
    props: {
        caseId: Number,
        isStudentCase: Boolean,
        identificationNumber: String,
        partnerName: String,
        opportunityNote: String,
        opportunitySummary: String,
        caseCounty: String,
        studentFormID: Number,
        studentInitials: String,
        studentComment: String,
        caseProblemCode: String,
        preferredPartner: {
            type: Boolean,
            default: false
        }
    },

    components: {
        'el-button': ElButton,
        'el-form': ElForm,
        'el-form-item': ElFormItem,
        'el-input': ElInput,
        'el-switch': ElSwitch
    },

    data () {
        return {
            formSubmissionStatus: 0,
            errorMessage: 'I\'m sorry, there was an issue saving your interest form.',
            displayForm: false,
            studentForm: {
                name: '',
                phone: '',
                email: '',
                comment: ''
            },
            alumniForm: {
                name: '',
                phone: '',
                email: '',
                barno: '',
                comment: '',
                mentorNeeded: false
            },
            rules: {
                name: [
                    { required: true, message: 'Please input your name', trigger: 'blur' },
                    { min: 3, message: 'Length should be at least 3', trigger: 'blur' }
                ],
                phone: [
                    { required: true, type: 'string', pattern: /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/, message: 'Please input number like XXX-XXX-XXXX.', trigger: 'blur' } // eslint-disable-line no-useless-escape
                ],
                email: [
                    { required: true, type: 'email', message: 'Please input your email address', trigger: 'blur' }
                ],
                barno: [
                    { required: false, message: 'Please input your Florida Bar No.', trigger: 'blur' }
                ],
                comment: [
                    { required: false, message: 'Anything you\'d like the mentor and case provider to know?', trigger: 'blur' }
                ]
            }
        }
    },

    computed: {
        isTaggedChallengeReady: function () {
            try {
                return this.opportunitySummary.toLowerCase().includes('#challengeready')
            } catch (e) {
                return false
            }
        },

        isTaggedRemote: function () {
            try {
                return this.opportunitySummary.toLowerCase().includes('#remote')
            } catch (e) {
                return false
            }
        },

        isPreferredPartner: function () {
            return this.preferredPartner
        },

        iconPath: function () {
            const basePath = 'img/case_type_icons/'

            let iCategoryCode

            try {
                iCategoryCode = parseInt(this.caseProblemCode.trim().substr(0, 2))
            } catch (e) {
                iCategoryCode = parseInt(10000)
            }

            if ((iCategoryCode > 1) && ((iCategoryCode <= 10))) {
                return basePath.concat('Consumer.png')
            } else if ((iCategoryCode >= 11) && ((iCategoryCode <= 20))) {
                return basePath.concat('Education.png')
            } else if ((iCategoryCode >= 21) && ((iCategoryCode <= 29))) {
                return basePath.concat('Employment.png')
            } else if ((iCategoryCode >= 30) && ((iCategoryCode <= 40))) {
                return basePath.concat('Family.png')
            } else if ((iCategoryCode >= 41) && ((iCategoryCode <= 50))) {
                return basePath.concat('Juvenile.png')
            } else if ((iCategoryCode >= 51) && ((iCategoryCode <= 60))) {
                return basePath.concat('Health.png')
            } else if ((iCategoryCode >= 61) && ((iCategoryCode <= 70))) {
                return basePath.concat('Housing.png')
            } else if ((iCategoryCode >= 71) && ((iCategoryCode <= 80))) {
                return basePath.concat('IncomeMaintenance.png')
            } else if ((iCategoryCode >= 81) && ((iCategoryCode <= 90))) {
                return basePath.concat('IndividualRights.png')
            } else {
                return basePath.concat('Miscellaneous.png')
            }
        },

        iconDescription: function () {
            let iCategoryCode

            try {
                iCategoryCode = parseInt(this.caseProblemCode.trim().substr(0, 2))
            } catch (e) {
                iCategoryCode = parseInt(10000)
            }

            if ((iCategoryCode > 1) && ((iCategoryCode <= 10))) {
                return 'A drawing of a stack of dollar bills and a pair of scales.'
            } else if ((iCategoryCode >= 11) && ((iCategoryCode <= 20))) {
                return 'A drawing of three books stacked on top of each other.'
            } else if ((iCategoryCode >= 21) && ((iCategoryCode <= 29))) {
                return 'A drawing of a laptop computer.'
            } else if ((iCategoryCode >= 30) && ((iCategoryCode <= 40))) {
                return 'A drawing of two people with a heart above them.'
            } else if ((iCategoryCode >= 41) && ((iCategoryCode <= 50))) {
                return 'A drawing of two todlers.'
            } else if ((iCategoryCode >= 51) && ((iCategoryCode <= 60))) {
                return 'A drawing of a stethascope.'
            } else if ((iCategoryCode >= 61) && ((iCategoryCode <= 70))) {
                return 'A drawing of a house.'
            } else if ((iCategoryCode >= 71) && ((iCategoryCode <= 80))) {
                return 'A drawing of a stack of dollar bills.'
            } else if ((iCategoryCode >= 81) && ((iCategoryCode <= 90))) {
                return 'A drawing of a set of scales.'
            } else {
                return 'A drawing of a box labeled "Misc".'
            }
        },

        mapDescription: function () {
            try {
                if (this.caseCounty === null) {
                    return 'A map displaying the state of Florida.'
                } else {
                    return 'A map of Florida displaying a pin at the center of '.concat(this.caseCounty, ' County')
                }
            } catch (e) {
                return ''
            }
        },

        mapPath: function () {
            // console.log(this.caseCounty)

            try {
                this.caseCounty.toLowerCase().includes('state')
                if (this.caseCounty === null) {
                    return 'img/maps/n+a.png'
                } else if (this.caseCounty.toLowerCase().includes('state')) {
                    return 'img/maps/n+a.png'
                } else {
                    const regex = /(%20)/g
                    return 'img/maps/'.concat(this.caseCounty.trim().replace(/( )/g, '+').replace('/', '+').replace(regex, '+').replace('.', '').trim().toLowerCase(), '.png')
                }
            } catch (e) {
                console.error(e)
                return ''
            }
        },

        formattedPartnerName: function () {
            if (this.partnerName.length >= 25) {
                return this.partnerName.substr(0, 25).concat('...')
            } else {
                return this.partnerName.substr(0, 25)
            }
        },

        recaptchaWidgetId: function () {
            return this.identificationNumber.concat('-recaptcha')
        }
    },

    methods: {
        runReCaptcha: function () {
            const self = this

            setTimeout(function () {
                // eslint-disable-next-line no-undef
                grecaptcha.enterprise.ready(function () {
                    // eslint-disable-next-line no-undef
                    grecaptcha.enterprise.execute('6Ldk80MkAAAAAPpJco2J8kM0vGgg8oidR0PMdi7J', { action: 'login' }).then(function (token) {
                        self.saveCase(token)
                    })
                })
            }, 100)
        },

        onSubmit: function () {
            let formRef

            if (this.isStudentCase) {
                formRef = 'studentForm'
            } else {
                formRef = 'alumniForm'
            }

            this.$refs[formRef].validate((valid) => {
                if (valid) {
                    this.runReCaptcha()
                }
            })
        },

        onExpired: function () {
            console.log('Expired')
        },

        toggleForm: function () {
            this.displayForm = !this.displayForm
        },

        saveCase: function (recaptchaResponse) {
            // grecaptcha.reset(self.recaptchaWidgetId) // eslint-disable-line no-undef

            this.formSubmissionStatus = 1

            const formData = {}
            let formURL = store.baseAPIURL

            if (this.isStudentCase) {
                formData.stu_name = this.studentForm.name
                formData.stu_phone = this.studentForm.phone
                formData.stu_email = this.studentForm.email
                formData.stu_comment = this.studentForm.comment
                formData.case_id = this.caseId.toString()

                formURL = formURL.concat(`cohort/${store.cohortId}/lawschool-challenge/schools/${store.teamId}/students/interest_forms?code=${recaptchaResponse}`)
            } else {
                formData.alumni_name = this.alumniForm.name
                formData.alumni_phone = this.alumniForm.phone
                formData.alumni_email = this.alumniForm.email
                formData.alumni_comment = this.alumniForm.comment
                formData.alumni_barno = this.alumniForm.barno
                formData.stu_form_id = this.studentFormID.toString()
                formData.mentor_needed = this.alumniForm.mentorNeeded

                formURL = formURL.concat(`cohort/${store.cohortId}/lawschool-challenge/schools/${store.teamId}/alumni/interest_forms?code=${recaptchaResponse}`)
            }

            const that = this

            axios.post(formURL, formData).then((response) => {
                that.formSubmissionStatus = 3

                EventBus.emit('caseUpdated', that.caseId)
            }).catch((error) => {
                if (error.response.status === 403) {
                    if (error.response.data.length > 4) {
                        that.errorMessage = error.response.data
                    } else {
                        that.errorMessage = 'I\'m sorry, there was a problem saving your interest form.'
                    }
                } else {
                    that.errorMessage = 'I\'m sorry, there was a problem saving your interest form.'
                }

                this.formSubmissionStatus = 2
            })
        }
    }
}
</script>

<style scoped>
div.CaseCard {
  width: 240px;
  padding: 16px;
  border: 1px solid lightgrey;
  border-radius: 8px;
  background-color: white;
  margin-bottom: 20px;
  margin-right: 20px;
  float: left;
  font-size: 11px;
  min-height: 480px;
}

div.InformationFace img.CaseTypeIcon {
  clear: left;
  float: left;
  margin-right: 10px;
}

div.InformationFace h1 {
  margin-top: 12px;
  margin-bottom: 0px;
  font-size: 13px;
  height: 40px;
  text-transform: none;
}

div.InformationFace h2 {
  font-size: 11px;
  font-weight: normal;
  margin: 0px;
  margin-top: 5px;
}

div.InformationFace p {
  font-size: 11px;
  height: 80px;
  margin-bottom: 0px;
}

div.InformationFace div#CaseCardControls .el-button.HelpButton {
  margin: 0px;
  float: right;
}

div.FormFace div#FormControls .el-button.HelpButton {
  background-color: #2a275b;
  border-color: #2a275b;
}

div.InformationFace div#CaseCardControls .el-button--primary {
  background-color: #2a275b;
  border-color: #2a275b;
}

div.InformationFace .MapImage {
  margin-top: 10px;
  margin-bottom: 10px;
}

div.FormFace div#FormControls {
  margin-top: 10px;
  font-size: 12px;
}

div.FormFace .el-form-item__label {
  font-size: 12px;
}

.GratitudeStmt {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

div#FormControls .CancelButton {
  border: none;
  float: right;
}

p#OppoSummaryNarrative {
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 4px;
  height: 80px;
}

div.StudentCommentRpt {
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 4px;
  height: 80px;
}

.el-form-item {
  margin-bottom: 10px;
}

label.el-form-item__label {
  line-height: 10px;
  font-size: 12px;
}

.el-checkbox {
    margin-top: 20px;
}

.el-switch {
    margin-top: 20px;
}

div#CaseChips {
  clear: both;
  min-height: 34px;
  margin-top: 12px;
  margin-bottom: 10px;
}

div#CaseChips .el-button {
  float: left;
  padding: 4px;
  margin: 0px 0px 0px 0px;
}

div#CaseChips .el-button:first-of-type {
  margin-right: 4px;
}

div#CaseChips div.material-icons {
  float: left;
  padding-left: 6px;
  padding-right: 6px;
}

div#CaseChips div.IconButtonLabel {
  float: left;
  padding-top: 4px;
  margin-right: 6px;
}
</style>
