<template>
  <div id="ParticipantTerms">
    <div class="Container" style="padding-top: 0px; padding-bottom: 0px;">
        <div v-if="store.roleId === 1">
            <student-terms />
        </div>
        <div v-else>
            <alumni-terms />
        </div>
    </div>
  </div>
</template>

<script>
import EventBus from '../EventBus'
import { store } from '../store'

import StudentTerms from './StudentTerms.vue'
import AlumniTerms from './AlumniTerms.vue'

export default {
    data () {
        return {
            store
        }
    },

    components: {
        'student-terms': StudentTerms,
        'alumni-terms': AlumniTerms
    },

    methods: {
        acceptParticipantTerms: function () {
            // console.log('CHECKBOX:' + store.student_term_a)
            EventBus.emit('participantTermsAccepted')
        },

        rejectParticipantTerms: function () {
            EventBus.emit('participantTermsRejected')
        }
    },

    mounted: function () {
    // console.log('Finished mounting "ConfirmTermsDialog"')
        return null
    }
}
</script>

<style scoped>
</style>
