import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

import 'element-plus/dist/index.css'
import './assets/glyphter-font/css/Glyphter.css'
import '../node_modules/material-design-icons/iconfont/material-icons.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// Vue.config.productionTip = false

const app = createApp(App)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.use(router)
app.mount('#app')

Sentry.init({
    app,
    dsn: 'https://344b32d6e9a348b7a1e464689b7048a9@o1009881.ingest.sentry.io/6157290',
    integrations: [
        new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracePropagationTargets: ['localhost', 'floridalawschoolchallenge.org', /^\//]
        })
    ],
    tracesSampleRate: 0.8,
    logErrors: true
})
