<template>
  <div class="Container">
    <div>
        <img :src="getSchoolLogoURL(school_id)" :alt="schoolLogoAltDescription(this.school_name)" width="50" height="50">
        <div id="SchoolName">{{ this.school_name }}</div>
        <div class="ClearBoth">&nbsp;</div>
    </div>
  </div>
</template>

<script>
import { store } from '../store'

export default {
    props: {
        school_id: {
            type: String,
            default: 'famu'
        },
        school_name: {
            type: String,
            default: 'Super Test School'
        }
    },

    methods: {
        getSchoolLogoURL: function (schoolAbbr) {
            try {
                return 'img/law_school_icons/'.concat(store.schools[this.school_id].logo_filename)
            } catch (e) {
                console.error(`A school with ID: ${schoolAbbr} was referenced, but does not exist.`)
                return 'img/law_school_icons/MissingSchoolLogo.png'
            }
        },

        schoolLogoAltDescription: function (schoolId) {
            return `Logo for ${this.school_name}`
        }
    }
}
</script>

<style scoped>
    .Container {
        border: 1px solid lightgray;
        border-radius: 5px;
        padding: 10px;
    }

    img {
        float: left;
        padding-right: 20px;
    }

    div#SchoolName {
        font-size: 1.1em;
        font-weight: bold;
        line-height: 50px;
        float: left;
    }
</style>
