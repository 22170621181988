<template>
  <div id="LeaderboardStage">
    <div class="Container">
      <h1>Student Leaderboard</h1>
      <div v-if="scores.students.length > 0">
        <div v-for="item in scores.students" :key="item.school_id" class="SchoolTile">
          <div v-if="item.rank >= 1">
            <div class="RankValue"># {{ item.rank }}</div>
            <img :src="getSchoolLogoURL(item.school_id)" :alt="schoolLogoAltDescription(item.school_id)" width="50"
              height="50">
            <div class="ClearBoth">&nbsp;</div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="EmptyScoreboard">The competition is just starting. Updated students rankings will appear here soon.</div>
      </div>
      <div class="ClearBoth">&nbsp;</div>
    </div>
    <div class="Container">
      <h1>Alumni Leaderboard</h1>
      <div v-if="scores.alumni.length > 0">
        <div v-for="item in scores.alumni" :key="item.school_id" class="SchoolTile">
          <div v-if="item.rank >= 1">
            <div class="RankValue"># {{ item.rank }}</div>
            <img :src="getSchoolLogoURL(item.school_id)" :alt="schoolLogoAltDescription(item.school_id)" width="50"
              height="50">
            <div class="ClearBoth">&nbsp;</div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="EmptyScoreboard">Updated alumni rankings will appear here soon.</div>
      </div>
      <div class="ClearBoth">&nbsp;</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

import { store } from '../store'

export default {
    data () {
        return {
            scores: {
                students: [
                    { school_id: 0, rank: -1 },
                    { school_id: 1, rank: -1 },
                    { school_id: 2, rank: -1 },
                    { school_id: 3, rank: -1 },
                    { school_id: 4, rank: -1 },
                    { school_id: 5, rank: -1 },
                    { school_id: 6, rank: -1 },
                    { school_id: 7, rank: -1 },
                    { school_id: 8, rank: -1 },
                    { school_id: 9, rank: -1 },
                    { school_id: 10, rank: -1 },
                    { school_id: 11, rank: -1 }
                ],
                alumni: [
                    { school_id: 11, rank: -1 },
                    { school_id: 10, rank: -1 },
                    { school_id: 9, rank: -1 },
                    { school_id: 8, rank: -1 },
                    { school_id: 7, rank: -1 },
                    { school_id: 6, rank: -1 },
                    { school_id: 5, rank: -1 },
                    { school_id: 4, rank: -1 },
                    { school_id: 3, rank: -1 },
                    { school_id: 2, rank: -1 },
                    { school_id: 1, rank: -1 },
                    { school_id: 0, rank: -1 }
                ]
            }
        }
    },

    methods: {
        getSchoolLogoURL: function (schoolId) {
            try {
                return 'img/law_school_icons/'.concat(store.schools[schoolId].logo_filename)
            } catch (e) {
                console.error(`A school with ID: ${schoolId} was referenced, but does not exist.`)
                return 'img/law_school_icons/MissingSchoolLogo.png'
            }
        },

        schoolLogoAltDescription: function (schoolId) {
            try {
                return 'The logo for '.concat(store.schools[schoolId].school_name)
            } catch (e) {
                console.error(`A school with ID: ${schoolId} was referenced, but does not exist.`)
                return 'The logo for a school that does not exist.'
            }
        }
    },

    mounted: function () {
        console.log(store.baseAPIURL)
        console.log(`No. of Schools in the Challenge: ${store.schools.length}`)

        const scoresURL = store.baseAPIURL.concat(`cohort/${store.cohortId}/lawschool-challenge`)
        const that = this

        axios.get(scoresURL)
            .then(function (response) {
                console.log('Score list updated')
                that.scores = response.data
            })
            .catch(function () {
                console.log('I was unable to update the score list')
            })
    }
}
</script>

<style scoped>
div.Container {
  padding: 1.5vw;
  background-color: #27292E;
  color: white;
  margin-bottom: 4vh;
  border-radius: 10px;
  margin-left: 1.5vw;
  margin-right: 1.5vw;
}

div.Container:last-of-type {
  margin-bottom: 4vh;
}

div.Container div.EmptyScoreboard {
  margin-top: 2vh;
  color: lightgray;
  font-style: italic;
}

h1 {
  font-size: 1.2em;
  font-weight: bolder;
  text-transform:capitalize;
  margin: 0vh 0px 2vh 0px;
}

#LeaderboardStage {
  padding: 10px;
}

.SchoolTile {
  background-color: white;
  border-radius: 10px;
  padding: 15px 15px 10px 15px;
  margin-right: 10px;
  margin-bottom: 10px;
  float: left;
}

.RankValue {
  float: left;
  padding-top: 12px;
  padding-right: 10px;
  width: 50px;
  color: black;
  font-size: 1.5em;
}

.RankLabel {
  width: 80px;
  float: left;
  clear: left;
  padding-top: 3px;
  padding-bottom: 4px;
}

img {
  position: relative;
}
</style>
