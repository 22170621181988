// store.js
import { reactive } from 'vue'

export const store = reactive({
    baseAPIURL: 'https://api.probono.savvysuit.com/v2/',
    cohortId: 0,
    roleId: -1,
    teamId: -1,
    acceptedParticipantTerms: false,
    schools: [
        {
            id: 0,
            active: true,
            school_name: 'Ave Maria School of Law',
            school_name_short: 'Ave Maria',
            primary_color_hex: '1D428A',
            accent_color_hex: '988642',
            logo_filename: 'ave_maria.png',
            banner_filename: 'ave_maria.jpg',
            alumScore: 0,
            stuScore: 0
        },
        {
            id: 1,
            active: true,
            school_name: 'Barry University School of Law',
            school_name_short: 'Barry',
            primary_color_hex: 'BA0C2F',
            accent_color_hex: '101820',
            logo_filename: 'barry.png',
            banner_filename: 'barry.jpg',
            alumScore: 0,
            stuScore: 0
        },
        {
            id: 2,
            active: true,
            school_name: 'Florida A&M University College of Law',
            school_name_short: 'FAMU',
            primary_color_hex: '205320',
            accent_color_hex: 'FB7516',
            logo_filename: 'famu.png',
            banner_filename: 'famu.jpg',
            alumScore: 0,
            stuScore: 0
        },
        {
            id: 3,
            active: false,
            school_name: 'Florida Coastal School of Law',
            school_name_short: 'Florida Coastal',
            primary_color_hex: '236192',
            accent_color_hex: '6BA4B8',
            logo_filename: 'fl_coastal.png',
            banner_filename: 'fl_coastal.jpg',
            alumScore: 0,
            stuScore: 0
        },
        {
            id: 4,
            active: true,
            school_name: 'Florida International University College of Law',
            school_name_short: 'FIU',
            primary_color_hex: '081E3F',
            accent_color_hex: 'B6862C',
            logo_filename: 'fiu.png',
            banner_filename: 'fiu.jpg',
            alumScore: 0,
            stuScore: 0
        },
        {
            id: 5,
            active: true,
            school_name: 'Florida State University College of Law',
            school_name_short: 'FSU',
            primary_color_hex: '782F40',
            accent_color_hex: 'CEB888',
            logo_filename: 'fsu.png',
            banner_filename: 'fsu.jpg',
            alumScore: 0,
            stuScore: 0
        },
        {
            id: 6,
            active: true,
            school_name: 'Nova Southeastern University College of Law',
            school_name_short: 'Nova',
            primary_color_hex: '003893',
            accent_color_hex: '7C858C',
            logo_filename: 'nsu.jpeg',
            banner_filename: 'nsu.jpg',
            alumScore: 0,
            stuScore: 0
        },
        {
            id: 7,
            active: true,
            school_name: 'Stetson University College of Law',
            school_name_short: 'Stetson',
            primary_color_hex: '3D8E33',
            accent_color_hex: '666666',
            logo_filename: 'stetson.png',
            banner_filename: 'stetson.jpg',
            alumScore: 0,
            stuScore: 0
        },
        {
            id: 8,
            active: true,
            school_name: 'St. Thomas University School of Law',
            school_name_short: 'St. Thomas',
            primary_color_hex: '862633',
            accent_color_hex: '13294B',
            logo_filename: 'stthomas.jpg',
            banner_filename: 'stthomas.jpg',
            alumScore: 0,
            stuScore: 0
        },
        {
            id: 9,
            active: true,
            school_name: 'University of Florida Levin College of Law',
            school_name_short: 'UF',
            primary_color_hex: 'FA4616',
            accent_color_hex: '0021A5',
            logo_filename: 'uf.png',
            banner_filename: 'uf.jpg',
            alumScore: 0,
            stuScore: 5
        },
        {
            id: 10,
            active: true,
            school_name: 'University of Miami School of Law',
            school_name_short: 'Miami',
            primary_color_hex: '005030',
            accent_color_hex: 'F47321',
            logo_filename: 'um.png',
            banner_filename: 'um.jpg',
            alumScore: 0,
            stuScore: 0
        },
        {
            id: 11,
            active: true,
            school_name: 'Western Michigan University Cooley Law School',
            school_name_short: 'Cooley',
            primary_color_hex: '3A5DAE',
            accent_color_hex: 'C5B9AC',
            logo_filename: 'cooley.png',
            banner_filename: 'cooley.jpg',
            alumScore: 0,
            stuScore: 0
        },
        {
            id: 12,
            active: true,
            school_name: 'Jacksonville University College of Law',
            school_name_short: 'JU',
            primary_color_hex: '014838',
            accent_color_hex: 'FDE8B1',
            logo_filename: 'jucol.png',
            banner_filename: 'jucol.jpg',
            alumScore: 0,
            stuScore: 0
        },
        {
            id: 13,
            active: true,
            school_name: 'Out-Of-State Law Students',
            school_name_short: 'Out-Of-State',
            primary_color_hex: '9A2037',
            accent_color_hex: 'FFFFFF',
            logo_filename: 'oos.png',
            banner_filename: 'oos.jpg',
            alumScore: 0,
            stuScore: 0
        }
    ],
    cases: []
})
