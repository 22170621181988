<template>
  <div id="TeamStage">
    <div style="width: 100%; clear: both;">
      <img src="../assets/img/FPBLawSchoolChallengeIcon_238x182.png" width="138" alt="The words 'Florida Pro Bono Law School Challenge' overliad on top of a shield" style="cursor: pointer; float: none !important;" @click="goHome()" />
    </div>
    <div style="clear: both;">&nbsp;</div>
    <div class="Container">
      <div v-if="store.acceptedParticipantTerms === false">
        <confirm-terms-widget />
      </div>
      <div v-else>
        <h1>Choose Your Team</h1>
        <div v-if="store.roleId === 0">
          <p>Choose the school that you attended. If you did not graduate from a Florida school, pick any team.
            #FLProBonoMatters to everyone.</p>
        </div>
        <div v-else>
          <p>Choose the school that you currently attend.</p>
        </div>
        <div v-for="school in activeSchools(store.schools)" :key="school.id" class="SchoolTile" @click="saveTeam(school.id)">
          <img :src="getSchoolLogoURL(school.logo_filename)" width="50" height="50">
          <div class="SchoolName">{{ school.school_name }}</div>
          <div class="ClearBoth">&nbsp;</div>
        </div>
      </div>
      <div class="ClearBoth">&nbsp;</div>
    </div>
  </div>
</template>

<script>
import EventBus from '../EventBus'
import ConfirmTermsDialog from '../components/ConfirmTermsDialog'
import { store } from '../store'

export default {
    data () {
        return {
            store
        }
    },
    methods: {
        goHome: function () {
            this.$router.push({ name: 'RoleSelector' })
        },

        saveTeam: function (store) {
            EventBus.emit('teamUpdated', store)
        },

        getSchoolLogoURL: function (sSchoolFilename) {
            return 'img/law_school_icons/'.concat(sSchoolFilename)
        },

        activeSchools: function (schoolsList) {
            return schoolsList.filter(school => school.active)
        }
    },

    components: {
        'confirm-terms-widget': ConfirmTermsDialog
    },

    beforeMount: function () {
        if (store.roleId === -1) {
            EventBus.emit('goHome')
        }
    }
}
</script>

<style scoped>
div.Container {
  padding-top: 0px;
}

h1 {
  text-transform: uppercase;
  margin-bottom: 40px;
  font-weight: bolder;
}

p {
  margin-bottom: 50px;
  font-size: 20px;
}

#TeamStage {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.SchoolTile {
  background-color: white;
  padding: 10px;
  float: left;
  margin-right: 16px;
  margin-bottom: 16px;
  width: 190px;
  height: 60px;
  cursor: pointer;
  border: 1px solid white;
}

.SchoolTile:hover {
  border: 1px solid #cfb66c;
}

.SchoolName {
  font-size: 14px;
}

img {
  float: left;
  margin-right: 10px;
}
</style>
