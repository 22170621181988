<template>
  <div>
        <h2>Student Code of Conduct</h2>
        <p>We're excited that you've chosen to participate in the Florida Pro Bono Law School Challenge! The work performed by students like you helps keep families together, obtain benefits for veterans, and support survivors of domestic violence.  The Challenge Team wants you to know what you're getting into.</p>
        <p><strong>Please check the box next to each of the following to indicate your agreement:</strong></p>
        <ol>
            <ul><input type="checkbox" v-model="student_term_a" id="student_term_a" name="student_term_a">I agree to respond promptly and professionally to the staff of the sponsoring legal aid program, the pro bono lawyer, and clients from the moment I am matched until the resolution of the case.</ul>
            <ul><input type="checkbox" v-model="student_term_b" id="student_term_b" name="student_term_b">I understand that if I select two cases and am matched on both, I will be expected to assist with both.</ul>
            <ul><input type="checkbox" v-model="student_term_c" id="student_term_c" name="student_term_c">I understand that I may be asked to prepare forms, conduct legal research, interview clients and witnesses, and, depending on if I’m a Certified Legal Intern, appear in court.</ul>
            <ul><input type="checkbox" v-model="student_term_d" id="student_term_d" name="student_term_d">I understand that some cases may extend beyond the end of the semester. <strong>I am committed to working on my case until the case or matter is properly and formally concluded.</strong></ul>
            <ul><input type="checkbox" v-model="student_term_e" id="student_term_e" name="student_term_e">I understand that not every case will be taken by a lawyer. I may cancel my interest form for a case at any time.</ul>
        </ol>
        <p>If at any time you are unable to reach your legal aid program or have concerns about working with your volunteer lawyer, please don't hesitate to contact Jessica at FFLA at <a
        href="mailto:jbrown@fundingfla.org?Subject=Law%20School%20Challenge%20Question">jbrown@fundingfla.org</a> or the sponsoring legal aid program.</p>
        <p><el-button slot="append" @click="acceptParticipantTerms()" style="background-color: #cfb66c; color: black">I Agree</el-button><el-button slot="append" @click="rejectParticipantTerms()">Back</el-button></p>
        <p class="WarningStatement">By clicking "I Agree", you are agreeing to comply with our Student Code of Conduct.</p>
  </div>
</template>

<script>
import EventBus from '../EventBus'
import { ElButton } from 'element-plus'

export default {
    data () {
        return {
            student_term_a: false,
            student_term_b: false,
            student_term_c: false,
            student_term_d: false,
            student_term_e: false
        }
    },

    components: {
        'el-button': ElButton
    },

    methods: {
        validateTerms (studentTermA, studentTermB, studentTermC, studentTermD, studentTermE) {
            if (studentTermA && studentTermB && studentTermC && studentTermD && studentTermE) {
                return true
            } else {
                return false
            }
        },

        acceptParticipantTerms: function () {
            if (this.validateTerms(
                this.student_term_a,
                this.student_term_b,
                this.student_term_c,
                this.student_term_d,
                this.student_term_e)
            ) {
                EventBus.emit('participantTermsAccepted')
            } else {
                alert('Please check the box next to each of the following terms to indicate your agreement.')
            }
        },

        rejectParticipantTerms: function () {
            EventBus.emit('participantTermsRejected')
        }
    }
}
</script>

<style scoped>
    h2 {
        margin-top: 0px;
        margin-bottom: 20px;
    }
    ol {
        padding-left: 0px;
    }
    ul {
        margin-bottom: 20px;
        list-style-type: none;
        padding-left: 0px;
    }
    strong {
        text-decoration: underline;
    }
    input {
        margin-right: 10px;
        height: 20px;
        width: 20px;
    }
    button {
        margin-right: 30px;
        padding: 20px;
    }
    p.WarningStatement {
        font-size: 0.8em;
        margin-top: 20px;
        color: red;
    }
</style>
