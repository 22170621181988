<template>
  <div id="AppStage">
    <div id="AppHeader"></div>
    <div id="AppContent">
      <router-view/>
    </div>
    <div id="AppFooter">
      <div class="Container">
        <div>&copy; SavvySuit <span>{{new Date().getFullYear()}}</span>. All rights reserved. <a href="https://www.savvysuit.com/pro-bono-matters-terms-of-use">Terms of Use</a></div>
        <div>Designed by <a href="https://fundingfla.org/">FFLA</a> in collaboration with <a href="https://www.savvysuit.com/">SavvySuit</a>.</div>
        <div>Data by <a href="https://www.legalserver.org/">LegalServer</a>/<a href="https://www.savvysuit.com">SavvySuit</a>.</div>
        <div style="margin-top: 20px;">If you have a question about the competition, please visit the <a href="https://fundingfla.org/faqs-florida-law-school-pro-bono-competition/">Florida Pro Bono Law School Challenge FAQ</a>.</div>
        <div style="font-weight: bolder;">We'd love to hear from you <a href="https://forms.gle/61VGbCBGk32sXvpx5">if you have an issue, suggestion, or question</a> about the Florida Pro Bono Law School Challenge.</div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from './EventBus'
import { store } from './store'

export default {
    data () {
        return {
        }
    },

    created: function () {
        EventBus.on('roleUpdated', this.setRole)
        EventBus.on('teamUpdated', this.setTeam)
        EventBus.on('caseUpdated', this.setCase)
        EventBus.on('goHome', this.goHome)
        EventBus.on('participantTermsAccepted', this.acceptParticipantTerms)
        EventBus.on('participantTermsRejected', this.rejectParticipantTerms)
    },

    methods: {
        acceptParticipantTerms: function () {
            store.acceptedParticipantTerms = true
        },

        rejectParticipantTerms: function () {
            store.acceptedParticipantTerms = false
            this.$router.push({ name: 'RoleSelector' })
        },

        setRole: function (newRoleId = -1) {
            if (store.roleId >= 0 && newRoleId !== store.roleId) {
                store.acceptedParticipantTerms = false
            }

            store.roleId = newRoleId
            console.log('Updated role id to:', store.roleId)
            this.$router.push({ name: 'TeamSelector' })
        },

        setTeam: function (newTeamId = -1) {
            store.teamId = newTeamId
            console.log('Updated team id to:', store.teamId)
            this.$router.push({ name: 'CaseSelector' })
        },

        setCase: function (newCaseId = -1) {
            store.caseId = newCaseId
            console.log('Updated case id to:', this.caseId)
            store.schools[9].alumScore = 1
            this.$router.push({ name: 'ChallengeOptions' })
        },

        goHome: function () {
            this.$router.push({ name: 'RoleSelector' })
        }
    }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Roboto');

body {
  font-family: 'Roboto', sans-serif;
  font-size: 1.0em;
  background-color: #27292E;
}

a {
  color: darkblue;
}

#AppFooter a {
  color: darkgrey;
}

div.ClearBoth {
  height: 0px;
  line-height: 0px;
  clear: both;
}
</style>

<style scoped>
#AppStage {
  padding: 2vw;
  color: black;
  background-color: #27292E;
}

#AppHeader {
  margin-bottom: 2vh;
}

#AppContent {
  background-color: white;
  border-radius: 10px;
  padding: 2vw;
}

#AppContent .Container {
  padding-bottom: 0px;
}

#AppFooter {
  color: grey;
  font-size: 11px;
  margin-top: 4vh;
}
</style>
