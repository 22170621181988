<template>
  <div>
    <h2>Lawyer Terms</h2>
    <p>We're excited that you'd like to participate in the Florida Pro Bono Law School Challenge! The work performed by pro
      bono heroes like you through the Challenge helps keep families together, obtain benefits for veterans, and
      support survivors of domestic violence. We're hoping that you can give a student an in-depth look at how a case
      is handled and the opportunity to meet various members of our legal community.  The Challenge Team wants you to know what you're getting into. </p>
    <p><strong>Please check the box next to each of the following to indicate your agreement:</strong></p>
    <ol>
      <ul><input type="checkbox" v-model="alumni_term_a" id="alumni_term_a" name="alumni_term_a">As the pro bono lawyer, I will be responsible for the management of the case I select until it is completed.
        Some cases finish quickly, but others may extend beyond a few weeks or months, or my student's school semester.</ul>
      <ul><input type="checkbox" v-model="alumni_term_b" id="alumni_term_b" name="alumni_term_b">I understand that, by clicking through to the next screen to view cases, I am not committed to a case. If I am
        interested in a case, I will submit an interest form. The legal aid program will then reach out to me for a
        conflict check and to confirm I am still interested in the case.</ul>
      <ul><input type="checkbox" v-model="alumni_term_c" id="alumni_term_c" name="alumni_term_c">I understand that the student will be able to assist me as a paralegal would (eg. prepare forms, conduct legal
        research, interview clients and witnesses) and, depending on whether they're a Certified Legal Intern, appear in
        court, but I am ultimately responsible for working on and completing the case while mentoring the student.</ul>
    </ol>
    <p>If at any time you need assistance or have concerns about working with your student or the legal aid program,
      please don't hesitate to contact Jessica at FFLA at <a
        href="mailto:jbrown@fundingfla.org?Subject=Law%20School%20Challenge%20Question">jbrown@fundingfla.org</a>
      or the sponsoring legal aid program.</p>
    <p><el-button slot="append" @click="acceptParticipantTerms()" style="background-color: #cfb66c; color: black">I Agree</el-button><el-button slot="append" @click="rejectParticipantTerms()">Back</el-button></p>
    <p class="WarningStatement">By clicking "I Agree", you are agreeing to comply with our Lawyer Terms.</p>
  </div>
</template>

<script>
import EventBus from '../EventBus'
import { ElButton } from 'element-plus'

export default {
    data () {
        return {
            alumni_term_a: false,
            alumni_term_b: false,
            alumni_term_c: false
        }
    },

    components: {
        'el-button': ElButton
    },

    methods: {
        validateTerms (alumniTermA, alumniTermB, alumniTermC) {
            if (alumniTermA && alumniTermB && alumniTermC) {
                return true
            } else {
                return false
            }
        },

        acceptParticipantTerms: function () {
            if (this.validateTerms(
                this.alumni_term_a,
                this.alumni_term_b,
                this.alumni_term_c)
            ) {
                EventBus.emit('participantTermsAccepted')
            } else {
                alert('Please check the box next to each of the following terms to indicate your agreement.')
            }
        },

        rejectParticipantTerms: function () {
            EventBus.emit('participantTermsRejected')
        }
    }
}
</script>

<style scoped>
    h2 {
        margin-top: 0px;
        margin-bottom: 20px;
    }
    ol {
        padding-left: 0px;
    }
    ul {
        margin-bottom: 20px;
        list-style-type: none;
        padding-left: 0px;
    }
    strong {
        text-decoration: underline;
    }
    input {
        margin-right: 10px;
        height: 20px;
        width: 20px;
    }
    button {
        margin-right: 30px;
        padding: 20px;
    }
    p.WarningStatement {
        font-size: 0.8em;
        margin-top: 20px;
        color: red;
    }
</style>
