import { createRouter, createWebHashHistory } from 'vue-router'

import RoleSelectorView from './views/RoleSelectorView.vue'
import TeamSelectorView from './views/TeamSelectorView.vue'
import CaseSelectorView from './views/CaseSelectorView.vue'
import ChallengeView from './views/ChallengeView.vue'
import DonateView from './views/DonateView.vue'

const challengeRoutes = [{
    path: '/',
    name: 'RoleSelector',
    component: RoleSelectorView
},
{
    path: '/teams',
    name: 'TeamSelector',
    component: TeamSelectorView
},
{
    path: '/cases',
    name: 'CaseSelector',
    component: CaseSelectorView
},
{
    path: '/challenge',
    name: 'ChallengeOptions',
    component: ChallengeView
},
{
    path: '/donate/',
    name: 'DonateOptions',
    component: DonateView
},
{
    path: '/donate/:GranteeCode',
    name: 'DonateOptions',
    component: DonateView
}
]

const router = createRouter({
    history: createWebHashHistory(),
    routes: challengeRoutes,
    base: process.env.BASE_URL,
    scrollBehavior (to, from, savedPosition) {
        return {
            x: 0,
            y: 0
        }
    }
})

export default router
