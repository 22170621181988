<template>
  <div id="CaseSelectorStage">
    <div style="width: 100%; clear: both;">
      <img src="../assets/img/FPBLawSchoolChallengeIcon_238x182.png" width="138" alt="The words 'Florida Pro Bono Law School Challenge' overliad on top of a shield" style="cursor: pointer; float: none !important;" @click="goHome()" />
    </div>
    <div class="Container">
      <el-row style="padding-bottom: 20px;">
        <el-col style="padding: 0px 10px 0px 00px;" :sm="24" :md="12" :lg="12">
          <div>
            <h1>Choose Your Case</h1>
            <div id="SearchTools">
              <div v-if="store.roleId === 1" style="max-height: 120px;">
                <el-input @input="debounceInput" placeholder="Search by Location, Legal Aid, or Keyword"
                  v-model="searchQuery" class="SearchBar" size="large">
                  <template #prepend>
                    <el-button @click="searchCases()">
                      <el-icon :size="20">
                        <Search />
                      </el-icon>
                    </el-button>
                  </template>
                </el-input>
              </div>
              <div v-else align="center">
                <div id="AlumniTools">
                  <el-switch style="display: block; margin-bottom: 0px; margin-top: 20px;" v-model="showAllStudents" active-color="#77101b"
                    inactive-color="#2a275b" active-text="Cases From All Schools" inactive-text="My School's Cases">
                  </el-switch>
                  <p style="text-align: left;">Cases below have been selected by law students at your alma mater. If you
                    don’t see any cases, or you don’t like the cases you see, you can choose a case from another school and still get points for your school! Toggle above to see other available cases.</p>
                  <p style="text-align: left;"><em>Cards that contain a blue "Preferred" chip indicate that the case is from a program with a strong commitment to supporting volunteers.</em></p>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :sm="24" :md="12" :lg="12">
          <div>
            <h1>Your Chosen Team</h1>
            <school-tile :school_id="store.teamId.toString()" :school_name="store.schools[store.teamId].school_name" />
            <div v-if="store.roleId === 0">
              <p style="text-align: left;">Enter your email address to be notified when a law student from your
                school has taken a new case.</p>
              <el-form :model="notificationForm" :rules="rules" ref="notificationForm">
                <el-form-item prop="email" id="AlumniNotificationInput">
                  <el-input placeholder="mentor@herolawfirm.com" v-model="notificationForm.email" ></el-input>
                </el-form-item>
                <el-button color="#CFBB58" class="NotifyButton" type="primary" @click="saveNotification()">Notify Me</el-button>
                <div class="ClearBoth">&nbsp;</div>
              </el-form>
            </div>
          </div>
        </el-col>
      </el-row>
      <div v-if="!firstCaseLoadComplete" align="center">
        <h2>Loading cases, one moment please.</h2>
      </div>
      <div v-else-if="!casesAvailableForDisplay" align="center">
        <h2>There appear to be no cases available at this time.</h2>
      </div>
      <div v-else>
        <div v-for="item in this.cases" :key="generateCardKey(item)" class="CaseCard">
          <case-card :isStudentCase=isStudentMode :caseId="item.case_id"
            :identificationNumber="item.identification_number" :partnerName="item.partner.partner_name"
            :preferredPartner="item.partner.preferred" :opportunityNote="item.pb_opportunity_note"
            :opportunitySummary="item.pb_opportunity_summary" :caseCounty="item.pb_opportunity_county"
            :studentFormID="item.student_interest_form_id" :caseProblemCode="item.legal_problem_code_descr"
            :studentInitials="item.student_initials" :studentComment="item.student_comment" />
        </div>
      </div>
      <div class="ClearBoth">&nbsp;</div>
      <donate-palette style="margin-top: 30px;" />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import CaseCard from '../components/CaseCard'
import SchoolTile from '../components/SchoolTile.vue'
import debounce from 'debounce'
import DonateWidget from '../components/DonateWidget.vue'
import EventBus from '../EventBus'
import { store } from '../store'

import { Search } from '@element-plus/icons-vue'
import { ElRow, ElCol, ElButton, ElForm, ElFormItem, ElInput, ElSwitch, ElIcon } from 'element-plus'

export default {
    data () {
        return {
            cases: [],
            searchQuery: '',
            showAllStudents: false,
            initialLoad: true,
            casesAvailableForDisplay: false,
            firstCaseLoadComplete: false,

            notificationForm: {
                email: ''
            },
            rules: {
                email: [
                    { required: true, type: 'email', message: 'Please input your email address', trigger: 'blur' },
                    { min: 6, max: 250, message: 'Emails addresses need to be between 5 and 250 characteres', trigger: 'blur' }
                ]
            },
            store
        }
    },

    components: {
        'school-tile': SchoolTile,
        'case-card': CaseCard,
        'donate-palette': DonateWidget,
        'el-button': ElButton,
        'el-form': ElForm,
        'el-form-item': ElFormItem,
        'el-input': ElInput,
        'el-switch': ElSwitch,
        'el-row': ElRow,
        'el-col': ElCol,
        'el-icon': ElIcon,
        Search
    },

    computed: {
        isStudentMode: function () {
            switch (store.roleId) {
            case 1:
                return true
            default:
                return false
            }
        }
    },

    watch: {
        showAllStudents: function (newShowAllStudents, oldShowAllStudents) {
            this.searchCases()
        },

        cases: function (newCases, oldCases) {
            try {
                if (newCases.length > 0) {
                    this.casesAvailableForDisplay = true
                } else {
                    this.casesAvailableForDisplay = false
                }
            } catch (e) {
                this.casesAvailableForDisplay = false
            }
        }
    },

    methods: {
        goHome: function () {
            this.$router.push({ name: 'RoleSelector' })
        },

        generateCardKey: function (caseItem) {
            if (typeof caseItem.student_interest_form_id === 'undefined') {
                return caseItem.identification_number + '-' + caseItem.case_id
            } else {
                return caseItem.student_interest_form_id
            }
        },

        debounceInput: debounce(function (e) {
            this.searchCases()
        }, 300),

        searchCases: function () {
            let casesURL = null
            let payload = {}

            if (store.roleId === 1) {
                // Retrieve student case list
                casesURL = store.baseAPIURL.concat(`cohort/${store.cohortId}/lawschool-challenge/search`)

                payload = {
                    query: this.searchQuery
                }
            } else {
                // Retrieve alumni case list
                if (this.showAllStudents) {
                    casesURL = store.baseAPIURL.concat(`cohort/${store.cohortId}/lawschool-challenge/schools`)
                    console.log('Fetching student cases for all schools.')
                } else {
                    casesURL = store.baseAPIURL.concat(`cohort/${store.cohortId}/lawschool-challenge/schools/${store.teamId}`)
                    console.log('Fetching student cases for one school.')
                }
            }

            const that = this

            axios.post(casesURL, payload)
                .then(function (response) {
                    console.log('Cases list updated')
                    that.cases = response.data
                    that.firstCaseLoadComplete = true
                })
                .catch(function () {
                    console.log('I was unable to update the case list')
                    that.cases = []
                })

            this.initialLoad = false
        },

        saveNotification: function () {
            this.$refs.notificationForm.validate((valid) => {
                if (valid) {
                    let formURL = store.baseAPIURL

                    formURL = formURL.concat(`cohort/${store.cohortId}/lawschool-challenge/schools/${store.teamId}/alumni/subscribe`)

                    axios.post(formURL, {
                        alumni_email: this.notificationForm.email
                    }).then((response) => {
                        if (response.status === 200) {
                            alert('Your request has been saved.  Check back here soon.')
                        } else {
                            alert(response.statusText)
                        }
                    }).catch(() => {
                        alert('I\'m sorry, we couldn\'t save your interest form.  Please try again later.')
                    })
                }
            })
        }
    },

    beforeMount: function () {
        if (store.roleId === -1) {
            EventBus.emit('goHome')
        } else {
            this.searchCases()
        }
    }
}
</script>

<style scoped>
.ClearBoth {
  clear: both;
  height: 0px;
}

.el-button {
  padding-top: 6px;
}

h1 {
  margin-bottom: 10px;
  font-weight: bolder;
}

#AlumniNotificationInput {
  width: 70%;
  margin-right: 20px;
  float:left;
}

#CaseSelectorStage {
  padding-left: 20px;
  padding-right: 20px;

}

div#SearchTools {
  clear: both;
  max-width: 800px;
}

div#SearchTools .el-switch__label * {
  font-size: 30px;
}

div#SearchTools .el-switch__label {
  font-size: 30px;
}

div#AlumniTools .el-switch {
  margin-bottom: 40px;
}

</style>
