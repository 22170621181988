<template>
  <div id="ChallengeStage">
    <div class="Container BannerImage">
      <img :src="bannerFileName" :alt="bannerDescription" />
    </div>
    <div id="ChallengeSubContent">
      <div class="Container">
        <div style="text-align: center;">
          <div v-if="store.roleId === 0">
            <h2 style="margin-bottom: 80px;">The legal aid sponsor for this case will contact you and your law student partner with the case file and additional information.  You should also receive a confirmation email in a few moments with additional information.</h2>
          </div>
          <div v-else>
            <h2 style="margin-bottom: 80px;">Your interest form has been saved and a confirmation email will be sent to you in a few moments.<br />When a lawyer selects your case, we will notify you and help the two of you connect.</h2>
          </div>
          <a href="https://www.linkedin.com/shareArticle?mini=true&url=FloridaLawSchoolChallenge.org&title=&summary=Will%20your%20law%20school%20win%20the%20Pro%20Bono%20%23LawSchoolChallenge?%20Take%20a%20case,%20mentor%20a%20law%20student,%20and%20score%20points%20for%20your%20school!&source=">
          <el-button type="primary">
            <div class="GlyphterButton">A</div>
            <div style="font-size: 20px;">LinkedIn</div>
          </el-button>
          </a>
          <a href="https://twitter.com/home?status=Will%20your%20law%20school%20win%20the%20Pro%20Bono%20%23LawSchoolChallenge?%20FloridaLawSchoolChallenge.org">
          <el-button type="primary">
            <div class="GlyphterButton">B</div>
            <div style="font-size: 20px;">Twitter</div>
          </el-button>
          </a>
          <a href="https://www.facebook.com/sharer/sharer.php?u=Will%20your%20law%20school%20win%20the%20Pro%20Bono%20%23LawSchoolChallenge?%20Take%20a%20case,%20mentor%20a%20law%20student,%20and%20score%20points%20for%20your%20school!%20FloridaLawSchoolChallenge.org">
          <el-button type="primary">
            <div class="GlyphterButton">C</div>
            <div style="font-size: 20px;">Facebook</div>
          </el-button>
          </a>
          <a href="https://www.instagram.com/fl_bar_found/">
          <el-button type="primary">
            <div class="GlyphterButton">D</div>
            <div style="font-size: 20px;">Instagram</div>
          </el-button>
          </a>
          <div class="ClearBoth">&nbsp;</div>
          <donate-palette style="margin-top: 30px;" />
        </div>
      </div>
      <leaderboard />
    </div>
  </div>
</template>

<script>
import DonateWidget from '../components/DonateWidget.vue'
import LeaderboardWidget from '../components/LeaderboardWidget.vue'
import { ElButton } from 'element-plus'
import EventBus from '../EventBus'
import { store } from '../store'

export default {
    data () {
        return {
            store
        }
    },

    components: {
        'donate-palette': DonateWidget,
        leaderboard: LeaderboardWidget,
        'el-button': ElButton
    },

    computed: {
        schoolShortName: function () {
            return store.schools[store.teamId].school_name_short
        },

        bannerDescription: function () {
            return `A photo of the front of the main building at ${store.schools[store.teamId].school_name}`
        },

        bannerFileName: function () {
            return `img/school_banners/${store.schools[store.teamId].banner_filename}`
        }
    },

    beforeMount: function () {
        if (store.roleId === -1) {
            EventBus.emit('goHome')
        }
    }
}
</script>

<style>
    i.el-select__caret.el-input__icon.el-icon-arrow-up {
        display: none;
    }
</style>

<style scoped>
.ClearBoth {
  clear: both;
  height: 0px;
}

a {
  margin-right: 20px;
}

a:last-child {
  margin-right: 0px;
}

.GlyphterButton {
  font-family: Glyphter;
  font-size: 20px;
  padding-right: 8px;
  float: left;
}

div.Container.BannerImage {
  padding-top: 0px;
  padding-bottom: 0px;
}

div.Container.BannerImage img {
  width: 100%;
}

#ChallengeSubContent {
  padding-top: 20px;
}

#ChallengeSubContent p {
  margin-bottom: 20px;
  font-size: 20px;
}

#ChallengeSubContent p:last-of-type {
  margin-bottom: 40px;
  font-size: 20px;
}

.el-button {
  width: 170px;
}
</style>
