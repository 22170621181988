<template>
  <div id="RoleStage">
    <div id="ProgramIntro">
      <el-row :gutter="20">
        <el-col id="lsc-header-left">
          <img src="../assets/img/FPBLawSchoolChallengeIcon_238x182.png" width="238" alt="The words 'Florida Pro Bono Law School Challenge' overliad on top of a shield" style="cursor: pointer; width: auto;" @click="goHome()" />
        </el-col>
        <el-col id="lsc-header-right"><div class="grid-content ep-bg-purple" />
          <h2>The Florida Pro Bono Law School Challenge</h2>
          <p>Law students are matched with lawyer mentors on pro bono cases hosted by legal aid organizations. Visit the <a href="https://fundingfla.org/faqs-florida-law-school-pro-bono-competition/">FAQs</a> or <a href="https://fundingfla.org/florida-pro-bono-law-school-challenge/">FFLA</a> for more information.</p>
        </el-col>
      </el-row>
    </div>
    <div>
      <h2 style="margin-left: auto; margin-right: auto; text-align: center;">On May 31, 2024, the Law School Challenge went on a hiatus.</h2>
    </div>
    <div id="RoleSubContent">
      <leaderboard />
    </div>
    <div id="DonorPanel">
      <el-row :gutter="20">
        <el-col :sm="12" :md="14" :lg="16"><div class="grid-content ep-bg-purple" />
          <h2>Don’t have time to participate?</h2>
          <p>Donate to a Florida legal aid program today. All donations are processed securely.</p>
          <p>Choose a local program below to support pro bono cases throughout Florida.</p>
          <div style="font-size: 18px;">Send your donation to: <el-select id="GranteeSelect" style="width: 300px;" @change="showBBMEForm()" v-model="BBMSFormId" placeholder="Select your favorite legal aid program">
            <el-option
            v-for="item in grantees"
            :key="item.code"
            :label="item.sitename"
            :value="item.BBMSFormId">
            </el-option>
            </el-select>
          </div>
        <div id="bbox-root" style="margin-top: 20px;"></div>
        </el-col>
        <el-col :sm="12" :md="10" :lg="8"><img src="img/OfficeScene.png" @click="saveRole(0)" alt="A picture of a group of people working in a office."></el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import EventBus from '../EventBus'
import { ElRow, ElCol, ElSelect, ElOption } from 'element-plus'
import LeaderboardWidget from '../components/LeaderboardWidget.vue'

export default {
    data () {
        return {
            BBMSFormId: null,
            grantees: [
                {
                    sitename: 'FFLA',
                    code: 'FBF',
                    BBMSFormId: '238dda2e-6c7e-4fad-96d8-cfb34d3cfbc6'
                },
                {
                    sitename: 'Americans for Immigrant Justice',
                    code: 'AIJ',
                    BBMSFormId: '116968e5-4807-4212-8a2d-34c70dc4da68'
                },
                {
                    sitename: 'Bay Area Legal Services',
                    code: 'BALS',
                    BBMSFormId: '4a83e41b-070a-4708-b447-b88bce925423'
                },
                {
                    sitename: 'Brevard County Legal Aid',
                    code: 'BCLA',
                    BBMSFormId: '25d1c0bf-80d4-405b-b7d7-066254d71493'
                },
                {
                    sitename: 'Catholic Legal Services',
                    code: 'CLS',
                    BBMSFormId: 'fe390ea0-9d77-440c-beec-d97fe252941d'
                },
                {
                    sitename: 'Community Law Program',
                    code: 'CLP',
                    BBMSFormId: 'f3bb21dd-7aec-4bec-8fe1-c667817fe0b7'
                },
                {
                    sitename: 'Community Legal Services of Mid-Florida',
                    code: 'CLSMF',
                    BBMSFormId: 'c1d18aa4-855e-409e-a6df-655b2338aca3'
                },
                {
                    sitename: 'Cuban American Bar Association Pro Bono Project',
                    code: 'CABA',
                    BBMSFormId: 'e52d3e02-9398-49b4-bd06-4a722edb5199'
                },
                {
                    sitename: 'Dade County Bar Association Legal Aid Society',
                    code: 'DADE',
                    BBMSFormId: 'fe184836-a112-4e98-83d7-39e9d15b5d0d'
                },
                {
                    sitename: 'Emerald Coast Legal Aid/ Northwest Florida Legal Services',
                    code: 'ECLA',
                    BBMSFormId: '56e9f967-3a55-4653-bfe4-3b585b62fa91'
                },
                {
                    sitename: 'Florida Justice Institute',
                    code: 'FJI',
                    BBMSFormId: '15868ef1-f803-4322-b711-35b173e04b8a'
                },
                {
                    sitename: 'Florida Legal Services',
                    code: 'FLS',
                    BBMSFormId: '229103c8-c87b-49dd-a73b-d232ad72d9a9'
                },
                {
                    sitename: 'Florida Rural Legal Services',
                    code: 'FRLS',
                    BBMSFormId: '766bc5e8-7fd6-4e1d-9ba2-995dca7b1ce6'
                },
                {
                    sitename: 'Gulfcoast Legal Services',
                    code: 'GCLS',
                    BBMSFormId: '282d12ca-05c8-446b-9b1b-6f20b8ac4e3b'
                },
                {
                    sitename: 'Heart of Florida Legal Aid Society',
                    code: 'HOFLA',
                    BBMSFormId: '7d7fddc5-735b-48c3-b146-8676c8462692'
                },
                {
                    sitename: 'IDignity',
                    code: 'IDIGNITY',
                    BBMSFormId: '078a486e-3940-4a73-b760-9960c3129f3d'
                },
                {
                    sitename: 'Jacksonville Area Legal Aid',
                    code: 'JALA',
                    BBMSFormId: '1900a3e6-7ee2-4a9f-a277-8cf8773eece7'
                },
                {
                    sitename: 'Lawyers for Children America',
                    code: 'LFCA',
                    BBMSFormId: '52d9e090-023d-4a31-90f4-b4648ef3fd20'
                },
                {
                    sitename: 'Lee County Legal Aid Society',
                    code: 'LCLAS',
                    BBMSFormId: '7a475af4-cced-4652-8bd0-a85d2417b5a5'
                },
                {
                    sitename: 'Legal Aid of Manasota',
                    code: 'LAM',
                    BBMSFormId: '1e387ff0-ae12-438c-93ee-7c0160f063a9'
                },
                {
                    sitename: 'Legal Aid Service of Broward County',
                    code: 'LASOBC',
                    BBMSFormId: '3972cbdd-b465-4e26-8bd4-4d840655a693'
                },
                {
                    sitename: 'Legal Aid Service of Collier County ',
                    code: 'LASOCC',
                    BBMSFormId: 'b270680a-f987-4476-b417-b9efaaa617ea'
                },
                {
                    sitename: 'Legal Aid Society of Palm Beach County',
                    code: 'LASOPBC',
                    BBMSFormId: '75b72812-1ebe-4f25-ab85-cd1c160c33d3'
                },
                {
                    sitename: 'Legal Aid Society of the Orange County Bar Association',
                    code: 'LASOCBA',
                    BBMSFormId: '098a62a6-5ff0-48b9-ab0e-49647adf4d35'
                },
                {
                    sitename: 'Legal Services of Greater Miami',
                    code: 'LSGMI',
                    BBMSFormId: '887f3a74-0db3-4b4a-9c0f-a2cfbcc2f8b0'
                },
                {
                    sitename: 'Legal Services of North Florida',
                    code: 'LSNF',
                    BBMSFormId: '083dd749-a5d7-449b-bce9-76475839631a'
                },
                {
                    sitename: 'Seminole County Bar Association Legal Aid Society',
                    code: 'SCBA',
                    BBMSFormId: '41ab75d5-0e5e-4463-a674-93b2be749616'
                },
                {
                    sitename: 'Southern Legal Counsel',
                    code: 'SLC',
                    BBMSFormId: '9d7ece95-164f-43b9-b463-4f1a1c9f9352'
                },
                {
                    sitename: 'The Legal Aid Foundation of the Tallahassee Bar Association',
                    code: 'LAFOTBA',
                    BBMSFormId: 'bef1fb7e-16fd-4509-a66c-a2bfd82082c2'
                },
                {
                    sitename: 'Three Rivers Legal Services',
                    code: 'TRLS',
                    BBMSFormId: '1f775cae-f72d-47e2-a3de-2d94e52cf197'
                }
            ]
        }
    },

    components: {
        'el-row': ElRow,
        'el-col': ElCol,
        'el-select': ElSelect,
        'el-option': ElOption,
        leaderboard: LeaderboardWidget
    },

    methods: {
        goHome: function () {
            this.$router.push({ name: 'RoleSelector' })
        },

        saveRole: function (newRoleId) {
            EventBus.emit('roleUpdated', newRoleId)
        },

        showBBMEForm: function () {
            global.BBMSFormId = this.BBMSFormId

            console.log(`Opening BBMS Form for: ${global.BBMSFormId}`);

            (function () {
                /* eslint-disable */
                var e = document.createElement('script'); e.async = true
                e.src = 'https://bbox.blackbaudhosting.com/webforms/bbox-min.js'
                document.getElementsByTagName('head')[0].appendChild(e)

                window.bboxInit = function () {
                    bbox.showForm(global.BBMSFormId)
                }
                /* eslint-enable */
            }())
        },

        findFormIdForGranteeCode: function (granteeCode) {
            const formId = null

            for (const grantee of this.grantees) {
                if (grantee.code === granteeCode.toUpperCase()) {
                    return grantee.BBMSFormId
                }
            }

            return formId
        },

        beforeMount: function () {
            if (this.$route.params.GranteeCode !== null && this.$route.params.GranteeCode !== undefined && this.$route.params.GranteeCode !== '') {
                const formId = this.findFormIdForGranteeCode(this.$route.params.GranteeCode)

                if (formId !== null) {
                    console.log(`Pre-selecting grantee code: ${this.$route.params.GranteeCode}`)
                    this.BBMSFormId = formId
                }
            }
        },

        mounted: function () {
            if (this.BBMSFormId !== null) {
                this.showBBMEForm()
            }
        }
    }
}
</script>

<style scoped>
h2 {
  margin-top: 0px;
  font-size: 24px;

}

p {
  font-size: 18px;
}

img {
  border: 1px solid white;
  border-radius: 10px;
  width: 100%;
}

img:hover {
  border: 1px solid #cfb66c;
}

#RoleStage {
  margin-left: auto;
  margin-right: auto;
}

#ProgramIntro {
  font-size: 1.25em;
  padding: 2vw;
}

#RoleSelection {
  padding: 2vw;
}

#DonorPanel {
  padding: 2vw;
  padding-top: 2.5vw;
}

#RolesList {
  padding-top: 50px;
  display: block;
  max-width: 660px;
  margin-left: auto;
  margin-right: auto;
}

#RoleStage h1 {
  text-transform: uppercase;
  margin-bottom: 0px;
  font-weight: bolder;
}

#RoleStage div.Intro {
  font-size: 1.25em;
  margin-bottom: 50px;
}

#RoleStage div.Intro a {
  color: darkgray;
}

#RoleStage a {
  color: lightblue;
}

#RoleSubContent {
  margin-top: 50px;
}

#lsc-header-left {
  max-width: 238px;
  margin-right: 2vw;
}

#lsc-header-right {
  flex: 1;
}

@media (max-width: 768px) {
  #lsc-header-left {
    width: 238px;
    margin-bottom: 2vh;
    margin-left: auto;
    margin-right: auto;
  }

  #lsc-header-right img {
    margin-left: auto;
    margin-right: auto;
  }

  #lsc-header-right {
    width: 100%;
  }
}
</style>
